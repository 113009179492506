<template>
  <v-container fluid>
      <v-row v-if="lineItems && lineItems.length" justify="center">
            <v-col cols="12" sm="12" md="7" lg="8" xl="9" v-if="false">   
            <v-card class="billingaddress mb-5">
                <v-card-title>Rechnungsadresse</v-card-title>
                <v-card-text>
                    <v-text-field v-model="billingaddress.lastname" placeholder="Firma" />
                    <v-text-field v-model="billingaddress.address" placeholder="Straße & Hausnummer" />
                    <v-text-field v-model="billingaddress.postalcode" placeholder="Postleitzahl" />
                    <v-text-field v-model="billingaddress.city" placeholder="Ort" />
                    <v-text-field v-model="billingaddress.phone" placeholder="Telefon" />
                    <v-text-field v-model="billingaddress.email" placeholder="E-Mail" />
                </v-card-text>
            </v-card>

            <v-card class="shippingaddress mb-5">
                <v-card-title>Lieferadresse</v-card-title>
                <v-card-text>
                    <v-text-field v-model="shippingaddress.lastname" placeholder="Firma" />
                    <v-text-field v-model="shippingaddress.address" placeholder="Straße & Hausnummer" />
                    <v-text-field v-model="shippingaddress.postalcode" placeholder="Postleitzahl" />
                    <v-text-field v-model="shippingaddress.city" placeholder="Ort" />
                    <v-text-field v-model="shippingaddress.phone" placeholder="Telefon" />
                    <v-text-field v-model="shippingaddress.email" placeholder="E-Mail" />
                </v-card-text>
            </v-card>

          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-card outlined>
                    <v-card-title>Übersicht</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                Rechnungsadresse
                                <v-btn icon small @click.stop="openAdressDialog('billing')">
                                    <v-icon small>mdi-pen</v-icon>
                                </v-btn>
                                <address v-if="addresses.billingaddress.lastname">
                                    <div v-if="addresses.billingaddress.lastname">
                                        <strong>{{ addresses.billingaddress.lastname }}</strong>
                                    </div>
                                    <div v-if="addresses.billingaddress.address">
                                        {{ addresses.billingaddress.address }}
                                    </div>
                                    <div v-if="addresses.billingaddress.postalcode">
                                        {{ addresses.billingaddress.postalcode }}
                                        {{ addresses.billingaddress.city }}
                                    </div>
                                    <div v-if="addresses.billingaddress.country">
                                        {{ addresses.billingaddress.country }}
                                    </div>
                                    <div v-if="addresses.billingaddress.phone">
                                        {{ addresses.billingaddress.phone }}
                                    </div>
                                    <div v-if="addresses.billingaddress.email">
                                        {{ addresses.billingaddress.email }}
                                    </div>                                    
                                </address>
                            </v-col>
                            <v-col cols="12" md="6">
                                Lieferadresse
                                <v-btn icon small @click.stop="openAdressDialog('shipping')">
                                    <v-icon small>mdi-pen</v-icon>
                                </v-btn>
                                <address v-if="addresses.shippingaddress.lastname">
                                    <div v-if="addresses.shippingaddress.lastname">
                                        <strong>{{ addresses.shippingaddress.lastname }}</strong>
                                    </div>
                                    <div v-if="addresses.shippingaddress.address">
                                        {{ addresses.shippingaddress.address }}
                                    </div>
                                    <div v-if="addresses.shippingaddress.postalcode">
                                        {{ addresses.shippingaddress.postalcode }}
                                        {{ addresses.shippingaddress.city }}
                                    </div>
                                    <div v-if="addresses.shippingaddress.country">
                                        {{ addresses.shippingaddress.country }}
                                    </div>
                                    <div v-if="addresses.shippingaddress.phone">
                                        {{ addresses.shippingaddress.phone }}
                                    </div>
                                    <div v-if="addresses.shippingaddress.email">
                                        {{ addresses.shippingaddress.email }}
                                    </div>                                    
                                </address>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-text class="mb-n5">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="customerReference" outlined placeholder="Ihr Zeichen" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFormatted"
                                            placeholder="Ihr Wunschlieferdatum"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="duedate"
                                        no-title
                                        scrollable
                                        @input="menu = false"
                                    />
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <!-- comment -->
                    <v-card-text class="mb-n5">
                        <v-textarea v-model="orderComment" outlined placeholder="Hinterlassen Sie einen Kommentar zu Ihrer Bestellung" />
                    </v-card-text>

                    <!-- internal comment -->
                    <v-card-text class="mb-n5" v-if="userAccount && userAccount.isCommAgent">
                        <v-textarea v-model="orderCommentInternal" outlined placeholder="Interne Anmerkung zur Bestellung" />
                    </v-card-text>

                    <v-card-text v-if="lineItems && lineItems.length">
                        <v-alert
                            v-if="hasRemovedItems"
                            type="warning"
                            dismissible
                        >
                            Einige Artikel sind nicht mehr verfügbar und wurden entfernt.
                        </v-alert>

                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Produktname</th>
                                        <th>Menge</th>
                                        <th>Einzelpreis</th>
                                        <th>Gesamtpreis</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in lineItems" :key="item.item">
                                        <td>
                                            <b>{{ item.itemnum }}</b><br>
                                            {{ item.name }}
                                            <div v-if="item.extdata.e_VPE"><i>VPE: {{ item.extdata.e_VPE.replace('*', '') }}</i></div>
                                        </td>
                                        <td class="text-right">{{ item.amount }}</td>
                                        <td class="text-right">{{ toPrice(item.sellingprice) }}</td>
                                        <td class="text-right">{{ toPrice(item.total) }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr v-if="cartTotalDiscount > 0">
                                        <th colspan="3" class="text-right">Nachlass</th>
                                        <th class="text-right">-{{ toPrice(cartTotalDiscount) }}</th>
                                        <th></th>
                                    </tr>

                                    <tr>
                                        <th colspan="3" class="text-right">Summe (netto)</th>
                                        <th class="text-right">{{ toPrice(cartTotalNet) }}</th>
                                    </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-text v-if="errors.length > 0">
                        <v-alert outlined dense type="warning">Bitte geben Sie eine Rechnungsadresse ein.</v-alert>
                    </v-card-text>               
                    <v-divider />
                    <v-card-actions>
                        <v-checkbox
                            v-model="chkquote"
                            :label="'Angebot anfordern'" v-if="userAccount && userAccount.isCommAgent"
                         ></v-checkbox>
                         <v-spacer/>
                        <v-btn :disabled="!cart || cart.length <= 0 || creatingOrder" color="primary" @click.stop="completeCheckout()" elevation="0">Einkauf abschließen</v-btn>
                        <v-progress-circular
                            v-if="creatingOrder"
                            class="ml-2"
                            indeterminate
                            color="primary"
                            :size="30"
                        ></v-progress-circular>
                    </v-card-actions>
              </v-card>
          </v-col>

          <v-dialog v-model="addressDialog" max-width="450">
              <v-card>
                  <v-card-title>{{ type === 'billing' ? 'Rechnungsadresse' : 'Lieferadresse' }} ändern</v-card-title>
                  <v-divider />
                  <v-alert type="error" tile color="red lighten-2" v-if="addressErrors.length > 0">Bitte alle Felder ausfüllen!</v-alert>
                  <v-card-text class="mt-3">Wählen Sie eine alternative Adresse oder legen Sie eine neue Adresse an.</v-card-text>
                  <v-card-text>
                    <v-select
                        v-if="addressList && addressList.length"
                        :items="addressList"
                        item-text="displayName"
                        item-value="ID"
                        v-model="selectedAddress"
                        @change="changeAddress()"
                        placeholder="Wählen Sie eine Adresse"
                    />
                  </v-card-text>
                  <v-card-text>
                    <v-text-field 
                        v-model="lastname"
                        placeholder="Firma"
                        :error="addressErrors.includes('lastnameError')"
                    />
                    <v-text-field 
                        v-model="address"
                        placeholder="Straße &amp; Hausnummer" 
                        :error="addressErrors.includes('addressError')"  
                    />
                    <v-text-field
                        v-model="postalcode"
                        placeholder="Postleitzahl"
                        :error="addressErrors.includes('postalcodeError')"    
                    />
                    <v-text-field 
                        value=""
                        v-model="city" 
                        placeholder="Ort" 
                        :error="addressErrors.includes('cityError')"
                    />
                    <v-select
                        v-if="config.allowed_countries.length > 0"
                        v-model="country"
                        :items="config.allowed_countries"
                        item-text="name"
                        item-value="value"
                        placeholder="Land"
                        :error="addressErrors.includes('countryError')"
                    />
                    <v-text-field v-model="phone" placeholder="Telefon" />
                    <v-text-field
                        value=""
                        v-model="email"
                        placeholder="E-Mail"
                        :error="addressErrors.includes('emailError')"  
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                      <v-spacer />
                      <v-btn @click.stop="setAddresses(address)" color="primary" elevation="0">Addresse speichern</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-row>

      <v-row v-else-if="orderSuccess" justify="center">
          <v-col cols="6" class="text-center">
            <h2>Vielen Dank für Ihre Bestellung</h2>
            <p>Ihre Bestellung ist bei uns eingangen und wird nun so schnell wie möglich von uns bearbeitet.</p>
            <p>Sie können den Status Ihrer Bestellung unter Ihrem Konto > Bestellungen jederzeit einsehen.</p>
            <v-btn to="/" color="primary" outlined>Zurück zum Shop</v-btn>
          </v-col>
      </v-row>

      <v-row v-else justify="center">
          <v-col cols="6" class="text-center">
              <h2>Keine Artikel vorhanden</h2>
              <p>Ihr Warenkorb ist leer. Bitte gehen Sie zur Artikelübersicht</p>
              <v-btn to="/" color="primary" outlined>Zurück zum Shop</v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { OrderService } from "@/services/order.service";
import { PriceHelper } from "@/helper/price.helper";
import _ from "lodash";

export default {
    name: "checkout", 

    data: () => ({
        errors: [],
        addressErrors: [],
        orderSuccess: false,
        selectedBillingaddress: null,
        selectedShippingaddress: null,
        selectedAddress: null,
        addressDialog: false,
        addresses: {
            billingaddress: {},
            shippingaddress: {}
        },
        lastname: null,
        address: null,
        postalcode: null,
        city: null,
        country: null,
        phone: null,
        email: null,
        chkquote: null,
        type: 'billing',
        orderComment: "",
        orderCommentInternal: "",
        customerReference: "",
        duedate: null,
        menu: false,
        creatingOrder: false,
    }),

    created() {
        this.setAddresses();
    },

    computed: {
        ...mapGetters("app", ["config"]),
        ...mapGetters("auth", ["userId"]),
        ...mapGetters("account", ["userAccount", "selectedAccount"]),
        ...mapGetters("persistedCart", ["cart", "lineItems", "cartTotalNet", "cartTotalDiscount", "cartTax", "hasRemovedItems"]),

        dateFormatted () {
            let formattedDate = this.duedate;

            if (this.duedate) {
                const [year, month, day] = this.duedate.split('-')

                formattedDate = `${day}.${month}.${year}`;
            }

            return formattedDate;
        },

        defaultAddresses() {
            let addresses = {
                billingaddress: null,
                shippingaddress: null,
            };

            let account = this.selectedAccount ? this.selectedAccount : this.userAccount;

            if (!account) return addresses;

            addresses.billingaddress = {
                lastname: account.lastname,
                address: account.address,
                postalcode: account.postalcode,
                city: account.city,
                country: account.country,
            }

            addresses.shippingaddress = {
                lastname: account.lastname,
                address: account.address,
                postalcode: account.postalcode,
                city: account.city,
                country: account.country,
            }

            if (!account.addresses) {
                return addresses;
            }

            let addressesBillingaddress = _.find(account.addresses, { 'type': '1', 'default': '1' });
            let addressesShippingaddress = _.find(account.addresses, { 'type': '0', 'default': '1' });

            if (addressesBillingaddress) {
                addresses.billingaddress = addressesBillingaddress;
            }

            if (addressesShippingaddress) {
                addresses.shippingaddress = addressesShippingaddress;
            }

            return addresses;
        },

        addressList() {
            if (this.selectedAccount && this.selectedAccount.addresses.length) {
                this.selectedAccount.addresses.forEach((address) => {
                    address.displayName = `${address.firstname} ${address.lastname}, ${address.address}, ${address.postalcode} ${address.city}`;
                });

                return this.selectedAccount.addresses;
            }

            if (!this.userAccount.addresses.length) {
                return [];
            }

            this.userAccount.addresses.forEach((address) => {
                address.displayName = `${address.firstname} ${address.lastname}, ${address.address}, ${address.postalcode} ${address.city}`;
            });

            return this.userAccount.addresses;            
        },
    },

    methods: {
        ...mapActions("persistedCart", ["getCartByAccountId", "removeCartByAccountId"]),

        completeCheckout() {
            this.errors = [];

            if (!this.defaultAddresses.billingaddress) {
                this.errors.push({ errorLastname: "Bitte einen Empfänger eingeben!" });
            }

            if (this.errors.length > 0) {
                return;
            }

            if (this.chkquote > 0) {
                console.log("chkquote", this.chkquote);
                this.transtype = 0;

            } else {
                 this.transtype = 1;
            }

            const order = {
                account: this.selectedAccount ? this.selectedAccount.ID : this.userId,
                orderedby: this.userId,
                type:  this.transtype,    
                comment: this.orderComment,
                commentIntern: this.orderCommentInternal,
                customerReference: this.customerReference,
                duedate: this.duedate ? Date.parse(this.duedate)/1000 : null,
                addresses: {
                    billingaddress: this.addresses.billingaddress,
                    shippingaddress: this.addresses.shippingaddress
                },
                lineitems: [...this.lineItems],
                netamount: this.totalNet,
                tax: this.cartTax,
                cartId: this.cart.ID
            };

            this.creatingOrder = true;

            let accountId = this.selectedAccount?.ID ? this.selectedAccount.ID : this.userId;

            OrderService.createOrder(order)
                .then(() => {
                    console.log('order created');

                    this.removeCartByAccountId(accountId)
                        .then(() => {
                            this.orderSuccess = true;
                        })
                        .catch((error) => { console.log('error', error); });

                    this.getCartByAccountId(accountId)
                        .then(() => {
                            console.log('cart', this.cart);
                        })
                        .catch((error) => { console.log('error', error); })
                })
                .catch((error) => { console.log('error', error); })
                .finally(() => {
                    this.creatingOrder = false;
                })
        },

        openAdressDialog(type) {
            this.type = type;
            this.addressDialog = true;
        },

        changeAddress() {
            if (this.selectedAccount && this.selectedAccount.addresses.length > 0) {
                let address = _.find(this.selectedAccount.addresses, ["ID", this.selectedAddress]);

                this.lastname = address.lastname;
                this.address = address.address;
                this.postalcode = address.postalcode;
                this.city = address.city;
                this.country = address.country;
                this.phone = address.phone;
                this.email = address.email;
            } else {
                let address = _.find(this.userAccount.addresses, ["ID", this.selectedAddress]);

                this.lastname = address.lastname;
                this.address = address.address;
                this.postalcode = address.postalcode;
                this.city = address.city;
                this.country = address.country;
                this.phone = address.phone;
                this.email = address.email;               
            }
        },

        setAddresses(address = null) {
            if (!address) {
                this.addresses.billingaddress = {
                    ...this.defaultAddresses.billingaddress
                }
                this.addresses.shippingaddress = {
                    ...this.defaultAddresses.shippingaddress
                }
            } else {
                this.validate();

                console.log('errors', this.addressErrors);

                if (this.addressErrors.length > 0) {
                    return false;
                }

                if (this.type === 'billing') {
                    this.addresses.billingaddress = {
                        lastname: this.lastname,
                        address: this.address,
                        postalcode: this.postalcode,
                        city: this.city,
                        country: this.country,
                        phone: this.phone,
                        email: this.email,
                    }
                } else {
                    this.addresses.shippingaddress = {
                        lastname: this.lastname,
                        address: this.address,
                        postalcode: this.postalcode,
                        city: this.city,
                        country: this.country,
                        phone: this.phone,
                        email: this.email,
                    }
                }

                this.addressDialog = false;
            }
        },

        validate() {
            this.addressErrors = [];

            if (!this.lastname) {
                this.addressErrors.push('lastnameError');
            }

            if (!this.address) {
                this.addressErrors.push('addressError');
            }

            if (!this.postalcode) {
                this.addressErrors.push('postalcodeError');
            }

            if (!this.city) {
                this.addressErrors.push('cityError');
            }

            if (!this.country) {
                this.addressErrors.push('countryError');
            }

            if (!this.email) {
                this.addressErrors.push('emailError');
            }
        },

        toPrice(price) {
            return PriceHelper.toPrice(price);
        }
    }
}
</script>
